<template>
  <v-container
    class="d-flex flex-column px-6 pt-6 pb-0"
    style="overflowY: auto; height: 100%; width: 100%; align-items: center"
    fluid
  >
    <v-col style="flex: 0; max-width: 1200px">
      <v-row class="mt-0 mb-6 text-h5 font-weight-bold textBlack--text">
        {{ $t('deconve.details') }}
      </v-row>
    </v-col>
    <v-col>
      <v-container style="max-width: 400px">
        <v-col>
          <v-row
            align="center"
            justify="center"
          >
            <button
              class="dropboxinput rounded"
              :style="{
                cursor: isAdmin ? 'pointer' : 'default',
                border: profileImg? '0px' : '1px solid',
                borderColor: $vuetify.theme.themes.light.border,
                backgroundColor: profileImg? 'white' : $vuetify.theme.themes.light.boxBackground,
                height:'150px',
                width:'150px',
              }"
              elevation="0"
              @mouseover="showAddImage = true"
              @mouseout="showAddImage = false"
            >
              <div
                v-if="isAdmin && showAddImage || !profileImg"
                class="
                  d-flex
                  flex-column
                  addImage
                  justify-center
                  align-center
                  rounded
                "
                style="
                  background: #F2F2F295;
                  position: absolute;
                  flex: 1;
                  width: 100%;
                  height: 100%;
                  flex-direction: column;
                  zIndex: 2;
                  align-items: center;
                  backdrop-filter: blur(4px)
                "
              >
                <v-icon
                  :size="$vuetify.breakpoint.mobile? 32 : 36"
                  class="mb-1"
                  color="neutral"
                >
                  mdi-image-outline
                </v-icon>
                <span
                  style="text-align: center"
                  class="neutral--text d-flex align-center font-weight-medium body-2 text-sm-body-1"
                >
                  {{
                    profileImg ? $t('deconve.setting.workspace.changeLogo')
                    : $t('deconve.setting.workspace.addLogo')
                  }}
                </span>
              </div>

              <input
                v-if="isAdmin"
                type="file"
                data-cy="input-image"
                accept="image/png, image/jpeg"
                class="input-file-logo"
                required
                @change="loadProfileImage($event.target.files)"
              >

              <v-img
                v-if="profileImg"
                data-dd-privacy="hidden"
                :src="profileImg"
                :style="{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                }"
                class="rounded"
                contain
                width="120"
                height="120"
              />
            </button>
          </v-row>
          <v-row class="mt-8 mt-sm-16">
            <div
              style="flex: 1"
            >
              <span class="font-weight-medium caption text-sm-body-2 neutralPrimary--text">
                {{ $t('deconve.setting.workspace.workspaceName' ) }}
              </span>
              <input-text
                v-model="name"
                data-dd-privacy="mask-user-input"
                :disabled="!isAdmin"
              />
            </div>
          </v-row>
          <v-row
            v-if="isAdmin"
            class="mt-8 justify-end"
          >
            <rectangle-button
              color="primary"
              icon="mdi-check"
              :disabled="!hasWorkspaceChanged"
              data-cy="workspace-submit-button"
              @clicked="handleEditWorkspace"
            >
              {{ $t('deconve.submit') }}
            </rectangle-button>
          </v-row>
        </v-col>
      </v-container>
    </v-col>
    <v-snackbar
      v-model="isAlertActive"
      :color="alertColor"
    >
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="alert = false"
        >
          <v-icon color="white">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import InputText from '@/components/InputText.vue';
import RectangleButton from '@/components/RectangleButton.vue';

export default {
  name: 'Workspace',
  components: {
    InputText,
    RectangleButton,
  },
  data() {
    return {
      isAlertActive: false,
      imageSizeLimit: 1000000,
      alert: {
        message: '',
        color: '',
      },
      showAddImage: false,
      profileImg: '',
      name: '',
    };
  },
  computed: {
    ...mapGetters({
      myWorkspace: 'workspace/myWorkspace',
      isAdmin: 'isAdminUser',
    }),
    alertMessage() {
      return this.alert.message;
    },
    alertColor() {
      return this.alert.color;
    },
    imageSizeToString() {
      return `${this.imageSizeLimit / 1000000}mb`;
    },
    hasWorkspaceChanged() {
      return this.myWorkspace.name !== this.name
          || this.myWorkspace.profile_image !== this.profileImg;
    },
  },
  watch: {
    myWorkspace() {
      this.getWorkspace();
    },
  },
  created() {
    this.getWorkspace();
  },
  methods: {
    ...mapActions({
      editWorkspace: 'workspace/editWorkspace',
    }),
    handleEditWorkspace() {
      this.editWorkspace({
        workspaceId: this.myWorkspace.id,
        data: {
          name: this.name.trim(),
          // eslint-disable-next-line @typescript-eslint/camelcase
          profile_image: this.profileImg,
        },
      }).then(() => {
        this.alert = {
          message: this.$t('deconve.setting.workspace.alert.success'),
          color: 'primary',
        };
        this.isAlertActive = true;
      }).catch(() => {
        this.alert = {
          message: this.$t('deconve.error.internalError'),
          color: 'error',
        };
        this.isAlertActive = true;
      });
    },
    loadProfileImage(files) {
      const file = files[0];

      const isImageSizeOk = file.size <= this.imageSizeLimit;

      if (isImageSizeOk) {
        const reader = new FileReader();

        reader.onload = () => {
          this.profileImg = reader.result;
        };

        reader.readAsDataURL(file);
      } else {
        this.isAlertActive = true;
        this.alert = {
          message: this.$t('deconve.setting.workspace.alert.imageSize', [this.imageSizeToString]),
          color: 'warn',
        };
      }
    },
    getWorkspace() {
      this.name = this.myWorkspace.name;
      this.profileImg = this.myWorkspace.profile_image;
    },
  },
};
</script>

<style>
  .dropboxinput {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .input-file-logo {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
    z-index: 2;
  }
</style>
